<template>
  <div class="w-full">
    <div style="margin-bottom: 16px">
      <RolesHeaderTable
        @search-term="onSearchTerm"
        :search="search"
      />
    </div>
    <div  v-if="!loading">
    <div
   
      class="w-full overflow-x-auto rounded-xl border border-gray-300 bg-white roles"
    >
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="data"
        :pagination="false"
      >
        <span
          slot="actions"
          slot-scope="text, record"
          class="text-sm font-medium text-right whitespace-nowrap flex"
        >
          <a-switch
            v-model="record.state"
            @change="onSwitchChange($event, record)"
          />
          <router-link
            :to="`./edit/?id=${record.id}`"
            class="
              ml-3
              h-6
              w-6
              p-1
              text-blue-600
              dark:text-blue-500
              shadow
              rounded
            "
            append
          >
            <img class="h-full w-full" src="@/assets/icons/pen.svg" alt="" />
          </router-link>
        </span>
      </a-table>
    </div>
    <div class="mt-4 flex justify-end">
      <a-pagination
        v-model="page"
        :page-size="pageSize"
        :page-size-options="pageSizeOptions"
        :total="count"
        show-size-changer
        size="large"
        @showSizeChange="onShowSizeChange"
      />
    </div>
      </div>

      <div v-if="loading" class="absolute w-full justify-center top-1/3 left-0 ">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import RolesHeaderTable from "@/components/Dashboard/Roles/RolesHeaderTable";
import ModalConfirmation from "./ModalConfirmation.vue";
import capitalize from "@/utils/checkForms.js";
const columns = [
  {
    title: "Nombre",
    dataIndex: "name",
    key: "name",
    width: 400,
  },
  {
    title: "Descripción",
    dataIndex: "description",
    key: "description",
    width: 500,
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    width: 100,
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    ModalConfirmation,
    RolesHeaderTable,
  },
  data() {
    return {
      data: [],
      columns,
      loading: false,
      current: null,
      showModal: false,
      page: 1,
      searchTerm: null,
      pageSize: 20,
      count: 0,
      search: null,
      pageSizeOptions: ['20', '40', '60', '80', '100']
    };
  },
  created() {
    if (this.$route.query.search) {
      this.searchTerm = this.$route.query.search;
      this.search = this.searchTerm;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 20;
    }
    this.start();
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.$router.replace({ query: { page: this.page, pageSize:this.pageSize } });
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
      return;
      } else {
        this.$router.replace({ query: { page: this.page, pageSize:this.pageSize } });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
  methods: {
    onSearchTerm(value) {
      this.showToast("info", "Buscando resultados...");
      this.searchTerm = value?.trim();
      this.search = this.searchTerm;
      this.$router.replace({
        query: { page: this.page, search: this.searchTerm },
      });
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.start();
      }
      this.page = 1;
    },
    async start() {
      let query = `?page=${this.page}&page_size=${this.pageSize}`;
      if (this.searchTerm) query += `&search=${this.searchTerm}`;
      this.loading = true;
      let { error, data } = await this.$api.readRole(query);
      this.loading = false;

      if (error) {
        this.data = [];
        this.showToast("error", "Error listando roles.");
      }
      if (data) {
        this.data = data.results.map((item) => {
          return {
            ...item,
            name: capitalize(item.name),
            description: item.detail_group ? capitalize(item.detail_group.description) : "",
            state: item.detail_group ? item.detail_group.state : false,
          };
        });
        this.count = data.count;
      }
    },
    async onSwitchChange(checked, record) {
      // Change: If state changes to false then routes to role-user view
      if (checked === false) {
        this.$router.push(`/dashboard/roles/usermanagement/${record.id}/${checked}`);
        return;
      }
      
      this.showToast("info", "Procesando cambio de estado...");
      this.loading = true;
      let { error, data } = await this.$api.changeStateRole(
        { state: checked },
        record.id
      ); //Change state
      this.loading = false;

      if (error) {
        this.showToast("error", "Error cambiando estado del usuario.");
        console.error(error)
      }

      if (data) {
        this.showToast("success", "Cambio de estado éxitoso.");
        this.start();
      }
    },
    onShowSizeChange(page, page_size){
    this.page = page
    this.pageSize = page_size
    this.start();

    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style >
.roles .ant-table-thead > tr > th {
  font-weight: bold;
  color:#6b7280;
}

</style>
