<template>
  <div>
      <RolesTable />
  </div>
</template>

<script>
import RolesTable from '@/components/Dashboard/Roles/RolesTable'
export default {
  components: {
    RolesTable
  }
}
</script>